import styled from 'styled-components';
import { Body2 } from '../../../../../../components/Base';

export const StyledRoadmap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
  height: max-content;
  p {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 980px) {
    margin: 0 auto;
    align-items: center;
    padding: 20px;
  }
`;

export const RoadmapWrapper = styled.div`
  -webkit-box-shadow: -2px 2px 37px 25px rgba(75, 201, 178, 0.21);
  -moz-box-shadow: -2px 2px 37px 25px rgba(75, 201, 178, 0.21);
  box-shadow: -2px 2px 37px 25px rgba(75, 201, 178, 0.21);
  /* position: absolute; */
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  border: 10px solid rgb(16, 170, 126);
  border-radius: 20px;

  height: inherit;
  gap: 180px;
  padding: 100px;

  @media screen and (max-width: 980px) {
    gap: 135px;

    @media screen and (max-width: 510px) {
      gap: 100px;
      width: 40%;
    }
  }

  @media screen and (max-height: 800px) {
    width: 39%;
    padding: 16px;
  }
`;

export const RoadItemWrapper = styled.div`
  :hover {
    .description-roadmap {
      display: block;
      padding: 25px;
      border-radius: 20px;
      color: white;
      width: max-content;
      background-color: rgb(17, 86, 67);
      position: absolute;
      margin: -70px -110px auto;
    }
  }
`;

export const RoadItem = styled(Body2)`
  position: relative;
  border-radius: 100%;
  background-color: rgb(16, 170, 126);
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;

  @media screen and (max-width: 980px) {
    width: 65px;
    height: 65px;
  }

  @media screen and (max-height: 800px) {
    width: 45px;
    height: 45px;
    font-size: 10px;
  }
`;

export const RoadDescription = styled(Body2)`
  display: none;
`;
