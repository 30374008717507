import styled from 'styled-components';

export const StyledMobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 80vh;
  height: 100vh;
  background: #0f444b;
  position: absolute;
  margin: 0;
  min-width: fit-content;
  transition: all 0.2s ease;
`;

export const StyledMenu = styled.div`
  display: contents;

  .wrapperMenu {
    display: none;
  }

  .wrapperMenu-visible {
    position: absolute;
  }
`;

export const RightMobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
