import { FC } from 'react';
import { Section } from '../../../../../components';
import { StyledAbout } from './styled';
import { Body2, H2 } from '../../../../../components/Base';
import { AboutSectionType } from '../../../types';

export const AboutSection: FC<AboutSectionType> = ({ head, description }) => {
  return (
    <Section
      marketing={{
        isMarketing: false,
      }}
      children={
        <StyledAbout>
          <H2 color="rgb(16, 170, 126)">{head}</H2>
          <Body2 color="white">{description}</Body2>
        </StyledAbout>
      }
    />
  );
};
