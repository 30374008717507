import React, { FC } from 'react';
import { Social } from './components/Social';
import { StyledFooter } from './styled';
import { FooterOptionType } from '../../app/Landing/types';
import { Body3, Logo } from '../Base';

export const Footer: FC<FooterOptionType> = ({ logo, companyName, socials }) => {
  return (
    <StyledFooter>
      <Logo logo={logo.url} altText={logo.altText} />
      <Body3 color="white" textAlign="center">
        {companyName}
      </Body3>
      <Social socials={socials} />
    </StyledFooter>
  );
};
