import styled, { css } from 'styled-components';

export const StyledCard = styled.div`
  width: max-content;
  height: max-content;
  background-color: rgb(16, 170, 126);
  flex-direction: column;
  border: none;
  border-radius: 20px;

  ${({ className }) =>
    (className === 'info-card' &&
      css`
        img {
          margin: 30px;
        }
        :hover {
          background: linear-gradient(203.28deg, #88a9ff 0%, #7976fd 86.01%);
          box-shadow: 0px 33px 80px rgba(66, 79, 236, 0.29), 0px 9.94853px 24.1177px rgba(66, 79, 236, 0.188961),
            0px 4.13211px 10.0172px rgba(66, 79, 236, 0.145), 0px 1.4945px 3.62304px rgba(66, 79, 236, 0.101039);
          div,
          h1 {
            color: white;
          }
        }
      `) ||
    (className === 'direct-card' &&
      css`
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(21.6772px);
        width: 310px;
        height: 524px;
        align-items: center;
        justify-content: center;
        img {
          margin: 0;
          width: 100%;
        }
        @media screen and (max-width: 1200px) {
          height: max-content;
        }
      `) ||
    (className === 'game-card' &&
      css`
        img {
          margin: 0;
          width: 100%;
          border-start-end-radius: 20px;
          border-start-start-radius: 20px;
        }
        width: 260px;
        height: 390px;
        transition: all 0.2s ease;
        -webkit-box-shadow: 7px 25px 24px 2px rgba(53, 190, 219, 0.2);
        -moz-box-shadow: 7px 25px 24px 2px rgba(53, 190, 219, 0.2);
        box-shadow: 7px 25px 24px 2px rgba(53, 190, 219, 0.2);

        @media screen and (max-width: 1200px) {
          height: max-content;
          width: 20%;
        }

        @media screen and (max-width: 680px) {
          width: 40%;
        }

        :hover {
          transition: all 0.2s ease;
          margin-top: -40px;
          background: rgb(43 229 176);
          -webkit-box-shadow: 0px 0px 36px 30px rgba(53, 190, 219, 0.2);
          -moz-box-shadow: 0px 0px 36px 30px rgba(53, 190, 219, 0.2);
          box-shadow: 0px 0px 36px 30px rgba(53, 190, 219, 0.2);
          div,
          h1 {
            color: white;
          }
        }
      `)}
`;

export const ImageCard = styled.img`
  position: relative;
  padding: 0;
  margin: 0;
`;

export const InfoWrapper = styled.div`
  position: relative;
  padding: 25px;

  ${({ className }) =>
    (className === 'info-card' &&
      css`
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1200px) {
          padding: 10px;
        }
      `) ||
    (className === 'direct-card' &&
      css`
        display: flex;
        flex-direction: column;
      `) ||
    (className === 'game-card' &&
      css`
        @media screen and (max-height: 375px) {
          padding: 8px;
        }
        @media screen and (max-width: 768px) {
        }
      `)}
`;
export const TitleCard = styled.span`
  position: relative;
`;
export const DescriptionCard = styled.span`
  position: relative;
`;
