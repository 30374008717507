import styled from 'styled-components';

export const StyledGame = styled.div`
  width: 70%;
  margin: 0 auto;

  iframe {
    height: calc(100vh - 283px);
    width: 100%;
    border: none;
    border-radius: 20px;

    -webkit-box-shadow: 0px 0px 53px 35px rgba(89, 221, 221, 0.36);
    -moz-box-shadow: 0px 0px 53px 35px rgba(89, 221, 221, 0.36);
    box-shadow: 0px 0px 53px 35px rgba(89, 221, 221, 0.36);
  }

  @media screen and (max-width: 980px) {
    width: 95%;

    iframe {
      height: calc(100vh - 115px);
      width: 100%;
    }
  }
  @media screen and (max-height: 420px) {
    iframe {
      height: calc(100vh - 65px);
    }
  }
`;
