export const navMenu = [
  {
    title: 'Home',
    path: '',
  },
  {
    title: 'About us',
    path: 'about',
  },
  {
    title: 'Project',
    path: 'project',
  },
  {
    title: 'RoadMap',
    path: 'roadmap',
  },
  {
    title: 'FAQ',
    path: 'faq',
  },
];
