export const gameMock = [
  {
    path: '/slot',
    title: 'Slot',
    description: 'so funny game on money',
    img: './images/card/cherry-slot.png',
  },
  {
    path: '/roulette',
    title: 'Roulette',
    description: 'so funny game on money',
    img: './images/card/deth-star-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/slot',
    title: 'Slot',
    description: 'so funny game on money',
    img: './images/card/cherry-slot.png',
  },
  {
    path: '/roulette',
    title: 'Roulette',
    description: 'so funny game on money',
    img: './images/card/deth-star-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/slot',
    title: 'Slot',
    description: 'so funny game on money',
    img: './images/card/cherry-slot.png',
  },
  {
    path: '/roulette',
    title: 'Roulette',
    description: 'so funny game on money',
    img: './images/card/deth-star-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/slot',
    title: 'Slot',
    description: 'so funny game on money',
    img: './images/card/cherry-slot.png',
  },
  {
    path: '/roulette',
    title: 'Roulette',
    description: 'so funny game on money',
    img: './images/card/deth-star-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/slot',
    title: 'Slot',
    description: 'so funny game on money',
    img: './images/card/cherry-slot.png',
  },
  {
    path: '/roulette',
    title: 'Roulette',
    description: 'so funny game on money',
    img: './images/card/deth-star-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
  {
    path: '/blackjack',
    title: 'Blackjack',
    description: 'so funny game on money',
    img: './images/card/heart-slot.png',
  },
];
