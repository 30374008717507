import styled, { css } from 'styled-components';

export const StyledHeader = styled.div`
  width: 100%
  height: 110px;
  top: 0;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 10% 70% 20%;

  @media screen and (max-width: 1400px) {
    display: none;
  }
  /* -webkit-box-shadow: -4px -3px 97px -15px rgba(34, 155, 167, 0.2) inset;
  -moz-box-shadow: -4px -3px 97px -15px rgba(34, 155, 167, 0.2) inset;
  box-shadow: -4px -3px 97px -15px rgba(34, 155, 167, 0.2) inset; */
`;

export const StyledMobileHeader = styled.div`
  display: none;

  @media screen and (max-width: 1400px) {
    width: 100vw;
    height: 110px;
    position: absolute;
    top: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-height: 420px) {
    height: 60px;
  }
`;

export const StyledLangAndButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderWrapper = styled.div`
  @media screen and (max-width: 980px) {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export const LoginAddress = styled.div`
  padding: 10px 40px 10px 40px;

  &:hover {
    cursor: pointer;
    border: 1px solid #10aa7e;
    border-radius: 20px;
  }
`;

export const RightHeadWrapper = styled.div`
  width: '100vw';
  display: 'flex';
`;

export const StyledProfileList = styled.div`
  position: absolute;
  height: max-content;
  width: 150px;
  margin-top: 10px;
  background-color: ${({ backgroundColor }: { backgroundColor: string }) => backgroundColor ?? 'white'};
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 100000000;

  @media screen and (max-width: 1400px) {
    margin-top: 20px;
  }
`;
