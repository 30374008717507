import styled from 'styled-components';

export const StyledLandingPage = styled.div`
  width: 100%;
`;

export const StyledContent = styled.div`
  height: 100vh;

  display: grid;
  grid-template-rows: 110px 1fr 110px;
`;

export const StyledBodyLanding = styled.div`
  display: flex;
  /* margin-top: 110px; */
  height: max-content;
  width: 100%;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 980px) {
    margin: 0 auto;
  }
`;

export const SideBarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border-right: 1px solid white;
`;

export const BodyWrapper = styled.div`
  display: grid;
  align-items: center;
  /* grid-template-columns: 130px 1fr; */

  @media screen and (max-width: 980px) {
    display: flex;
  }
`;

export const BackgroundVideo = styled.video`
  display: block;
  top: -20%;
  left: -20%;
  width: 140%;
  height: 140%;
  position: fixed;
  z-index: -1;
  -o-object-fit: contain;
  object-fit: contain;

  @media screen and (max-width: 1580px) {
    width: auto;
  }

  @media screen and (max-height: 900px) {
    height: auto;
  }
`;
