export const socials = [
  {
    imgUrl: '/images/social/twitter.svg',
    altText: 'twitter',
  },
  {
    imgUrl: '/images/social/twitter.svg',
    altText: 'twitter',
  },
  {
    imgUrl: '/images/social/twitter.svg',
    altText: 'twitter',
  },
];
