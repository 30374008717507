import { FC, useId } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { NavMenuPropsType } from '../../../../app/Landing/types';
import { NavMenuItem, StyledNavMenu } from './styled';
import { appStoreActions } from '../../../../redux/reducer/app-reducer/reducer';
import { RootState } from '../../../../redux/store';

export const NavMenu: FC<NavMenuPropsType> = ({ menu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogin } = useSelector(({ AppReducer }: RootState) => AppReducer);

  const setSelectedTabLanding = (tabName: string) => {
    isLogin 
      ? navigate(`/${tabName}`) 
      : dispatch(appStoreActions.setSelectedTabLanding(`#${tabName}`));
  };

  return (
    <StyledNavMenu>
      {menu.map((element) => {
        return (
          <NavMenuItem key={`nav_menu-${useId()}`} onClick={() => setSelectedTabLanding(element.path)}>
            {element.title}
          </NavMenuItem>
        );
      })}
    </StyledNavMenu>
  );
};
