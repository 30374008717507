import { FC } from 'react';
import { Section } from '../../../../../components';
import { StyledProject } from './styled';
import { ProjectSectionType } from '../../../types';
import { H2, Body2 } from '../../../../../components/Base';

export const ProjectSection: FC<ProjectSectionType> = ({ head, description }) => {
  return (
    <Section
      marketing={{
        isMarketing: false,
      }}
      children={
        <StyledProject>
          <H2 color="rgb(16, 170, 126)">{head}</H2>
          <Body2 color="white">{description}</Body2>
        </StyledProject>
      }
    />
  );
};
