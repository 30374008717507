import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MobileMenu } from './components/MobileMenu';
import { NavMenu } from './components/NavMenu';
import {
  StyledLangAndButton,
  StyledHeader,
  StyledMobileHeader,
  LoginAddress,
  StyledProfileList,
  RightHeadWrapper,
} from './styled';
import { HeaderOptionType } from '../../app/Landing/types';
import { Button, Logo, Body2, SelectLanguageContainer } from '../Base';
import { connectDapp } from '../../services/contract/src/contract';
import { RootState } from '../../redux/store';
import { appStoreActions } from '../../redux/reducer/app-reducer/reducer';

export const ProfileList: FC<{ setIsOpenProfileList: Dispatch<SetStateAction<boolean>> }> = ({
  setIsOpenProfileList,
}) => {
  const dispatch = useDispatch();

  const closeProfileList = () => {
    setIsOpenProfileList(false);
  };

  const exitDapp = () => {
    dispatch(appStoreActions.resetApp());
    window.location.reload();
    setIsOpenProfileList(false);
    window.location.pathname = '/';
  };

  return (
    <StyledProfileList backgroundColor="#10aa7e">
      <Body2 onClick={closeProfileList} color="white">
        Setting
      </Body2>
      <Body2 onClick={exitDapp} color="white">
        Exit
      </Body2>
    </StyledProfileList>
  );
};

export const Header: FC<HeaderOptionType> = ({ logo, navMenu, button, isMobile }) => {
  const dispatch = useDispatch();
  const { isLogin, userAddress } = useSelector(({ AppReducer }: RootState) => AppReducer);
  const [isOpenProfileList, setIsOpenProfileList] = useState<boolean>(false);

  const connectingToDapp = () => connectDapp(dispatch);

  const openProfileList = () => {
    setIsOpenProfileList(true);
  };

  return (
    <div>
      <StyledHeader onClick={() => isOpenProfileList && setIsOpenProfileList(!isOpenProfileList)}>
        <Logo logo={logo.url} altText={logo.altText} />
        <NavMenu menu={navMenu} />
        <StyledLangAndButton>
          <SelectLanguageContainer />
          <RightHeadWrapper>
            {isLogin ? (
              <LoginAddress onClick={openProfileList}>
                <Body2 color="#10aa7e">{`${userAddress.slice(0, 5)}...${userAddress.slice(-4)}`}</Body2>
              </LoginAddress>
            ) : (
              <Button type={button.buttonType} children={button.buttonName} handleClick={() => connectingToDapp()} />
            )}
            {isOpenProfileList && <ProfileList setIsOpenProfileList={setIsOpenProfileList} />}
          </RightHeadWrapper>
        </StyledLangAndButton>
      </StyledHeader>
      {isMobile && (
        <StyledMobileHeader>
          <MobileMenu menu={navMenu} />
        </StyledMobileHeader>
      )}
    </div>
  );
};
