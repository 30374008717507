export const description = (
  <>
    <p>
      We are a team of web3 developers. More than one project has been launched for different blockchain networks. We
      sold fintech products and de-fi schemes.{' '}
    </p>
    <p>
      We are broadly competent in matters of information technology, marketing, financial system, economics, business
      planning, business process design and decentralized technologies. We understand the market and finance. We know
      what a client is and we know what is best for him.
    </p>
    <p>We are inspired by the idea of digital anarchy and anonymity on the web.</p>
    <p>With love Enki Game</p>
  </>
);
