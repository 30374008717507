import { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { BackgroundVideo, BodyWrapper, StyledBodyGambling, StyledContent, StyledGamblingPage } from './styled';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { HelloSection } from '../Landing/components/Tabs';
import { GamblingOptionType } from '../Landing/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { AppSagaAction } from '../../redux/saga/app-saga/saga-actions';
import { GamesDashboard } from './components/GamesDashboard';
import { Game } from './components/Game';

export const Gambling: FC<GamblingOptionType> = ({ content }) => {
  const dispatch = useDispatch();
  const { userAddress, chainId } = useSelector(({ AppReducer }: RootState) => AppReducer);

  const { header, tabs, footer } = content;

  useEffect(() => {
    dispatch(
      AppSagaAction.notification({
        isActive: true,
        mode: 'success',
        message: `
          You success login!
          Your address: ${userAddress}
          Your chainId: ${chainId}
        `,
      }),
    );
  }, []);

  return (
    <StyledGamblingPage className={'gambling'}>
      <StyledContent>
        <Header {...header} />
        <BodyWrapper>
          <StyledBodyGambling>
            <Routes>
              <Route path="/" element={<GamesDashboard />} />
              <Route path="/slot" element={<Game src="https://slot.enkigame.io" />} />
              <Route path="/roulette" element={<Game src="https://docs.ipfs.tech/concepts/#learn-the-basics" />} />
              <Route path="/blackjack" element={<Game src="https://reactdev.ru/libs/react-router/#switch" />} />
            </Routes>
          </StyledBodyGambling>
        </BodyWrapper>
        <Footer {...footer} />
        {/* <BackgroundVideo src="./background/background.mp4" autoPlay muted loop /> */}
      </StyledContent>
    </StyledGamblingPage>
  );
};
