import styled from 'styled-components';

export const StyledProject = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;

  p {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 980px) {
    margin: 0 auto;
    align-items: center;
    padding: 20px;
  }
`;
