export const description = (
  <ul>
    <li>
      This project is a combination of our developments and research of the development department in the field of
      blockchain technologies.
    </li>
    <br />
    <li>GPT chat/OpenAI was used during the development process. That helped generate content game mechanics.</li>
    <br />
    <li>
      For dapp, we used our own sdk (our own stack and an effective set of libraries, as well as a number of
      architectural approaches to improve support and scaling applications)
    </li>
    <br />
    <li>We also have designed our own smart contract integration module, which makes the frontend - dapp.</li>
    <br />
    <li>
      Game mechanics are implemented based on the generation of random numbers. Probability theory is on your side.
    </li>
    <br />
    <li>
      We do not store user information and do not take on the role of a deposit vault. Each operation made in our
      service is a new transaction. Win - get a win to your wallet.
    </li>
  </ul>
);
