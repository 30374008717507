import styled, { css, keyframes } from 'styled-components';

export const StyledFaq = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 800px;
  height: max-content;
  p {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 980px) {
    margin: 0 auto;
    align-items: center;
    padding: 20px;
  }
`;

export const FaqWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
`;

const opening = keyframes`
  from {
    opacity: 0;
    margin-top: -20px;
    display: none;
  } to {
    opacity: 1;
    margin: 0;
    display: block;
  }
`;

const closing = keyframes`
  from {
    opacity: 1;
    display: block;
  } to {
    opacity: 0;
    margin-top: -20px;
    display: none;
  }
`;

export const QuestionWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 57px 21px rgba(47, 128, 131, 0.2) inset;
  -moz-box-shadow: 0px 0px 57px 21px rgba(47, 128, 131, 0.2) inset;
  box-shadow: 0px 0px 57px 21px rgba(47, 128, 131, 0.2) inset;

  :hover {
    -webkit-box-shadow: 0px 0px 57px 21px rgba(81, 210, 216, 0.2) inset;
    -moz-box-shadow: 0px 0px 57px 21px rgba(81, 210, 216, 0.2) inset;
    box-shadow: 0px 0px 57px 21px rgba(81, 210, 216, 0.2) inset;
  }
`;

export const AnswerWrapper = styled.div<{ active: boolean }>`
  ${({ active }) =>
    active &&
    css`
      animation: ${opening};
      animation-duration: 0.15s;
    `};
`;
