import styled, { css } from 'styled-components';
import { TypeButton } from '../../../../app/Landing/types';

const primaryL = css`
  background-color: #10aa7e;

  :hover {
    background-color: #36e0b0;
  }

  :active {
    background-color: #115643;
    p {
      color: #b7bce4;
    }
  }
`;

const primaryB = css`
  background-color: #624bff;

  :hover {
    background: #8061ff;
    box-shadow: 0px 5px 10px rgba(98, 75, 255, 0.5);
  }

  :active {
    background-color: #4634c6;
  }
`;

const secondaryL = css`
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);

  :hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  :active {
    border: 1px solid rgba(255, 255, 255);
  }
`;

const disabledPrimaryL = css`
  background-color: #b7bce4;
  color: #eaedfe;
`;
const disabledPrimaryB = css`
  background-color: #b1a5ff;
  color: #e0e3ff;
`;
const disabledSecondaryL = css`
  background-color: #eaedfe;
  border: 1px solid #b7bce4;
  color: #b7bce4;
`;

export const StyledButton = styled.div<{
  type: TypeButton;
  disabled?: boolean;
  borderRadius?: string;
  backgroundColor?: string;
  color?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 16px 14px;
  border-radius: 50px;
  inline-size: max-content;

  font-family: 'Mazzard';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.035em;
  text-transform: uppercase;

  color: ${({ color }) => color};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ type, disabled }: { type: TypeButton; disabled?: boolean }) =>
    type === 'primary-l'
      ? disabled
        ? disabledPrimaryL
        : primaryL
      : type === 'primary-b'
      ? disabled
        ? disabledPrimaryB
        : primaryB
      : disabled
      ? disabledSecondaryL
      : secondaryL};

  @media screen and (max-width: 720px) {
    width: inherit;
  }
`;
