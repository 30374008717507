import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMarketingState, MarketingType } from '../../types';

const initialState: IMarketingState = {
  marketing: {
    title: '',
    client: '',
    mode: '',
    material: '',
  },
};

export const MarketingStore = createSlice({
  name: 'MarketingStore',
  initialState,
  reducers: {
    setMarketing(state, { payload }: PayloadAction<MarketingType>) {
      state.marketing = payload;
    },
  },
});

export const MarketingActions = {
  ...MarketingStore.actions,
};

export const MarketingReducer = MarketingStore.reducer;
