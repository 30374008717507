import { Contract, ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';

import { abiQualifier, contractMapping, contractQualifier } from './utils/contractHelpers';
import { Dispatch } from 'redux';
import { AppSagaAction } from '../../../redux/saga/app-saga/saga-actions';
import { appStoreActions } from '../../../redux/reducer/app-reducer/reducer';

export const getProvider = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  return provider;
};

export const getSigner = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  return signer;
};

export const getContract = (type: TypeContract): Contract => {
  const signer = getSigner();
  const contract = new ethers.Contract(contractQualifier(type), abiQualifier(type), signer);

  return contract;
};

export const getMethodsContract = <T extends { [key: string]: unknown }>(type: TypeContract): T => {
  try {
    if (!window.ethereum) {
      throw new Error('Not found provider window.ethereum');
    }
    const contract = getContract(type);

    const methodsContract = Object.fromEntries(
      Object.entries(contract).filter(([key, value]) => contractMapping(key, type)),
    ) as T;

    return methodsContract;
  } catch (error: unknown) {
    const { message } = error as { message: string };
    console.error(message);
    return getMethodsContract<T>(type);
  }
};

export const sendTransaction = (to: string, value: string) => {
  const signer = getSigner();
  signer.sendTransaction({
    to,
    gasLimit: parseEther('0.0000000000021'),
    value: parseEther(value),
  });
};

export const checkNetworkBlockchain = async (dispatch: Dispatch) => {
  const signer = getSigner();
  const chainId = await signer.getChainId();

  if (chainId !== ChainId.BNBTestnet) {
    dispatch(
      AppSagaAction.notification({
        isActive: true,
        mode: 'info',
        message: 'You need to change the network to Bnb testnet',
      }),
    );
  }
};

export const connectDapp = async (dispatch: Dispatch) => {
  try {
    const provider = getProvider();
    await provider.send('eth_requestAccounts', []);

    const chainId = await provider.getSigner().getChainId();
    const address = await provider.getSigner().getAddress();

    [
      appStoreActions.setChainId(chainId),
      appStoreActions.setUserAddress(address),
      appStoreActions.setLogin(true),
    ].forEach((action) => {
      dispatch(action);
    });
  } catch (error: unknown) {
    const { message } = error as { message: string };

    dispatch(
      AppSagaAction.notification({
        isActive: true,
        mode: 'error',
        message: `Error: ${message}`,
      }),
    );
  }
};
