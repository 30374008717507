import styled, { css } from 'styled-components';

export const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  gap: 30px;
  z-index: 1;
  width: 100%;

  @media screen and (max-width: 768px) {
    gap: 10px;
    /* margin-top: 50px; */
  }
`;

export const CountPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageItem = styled.button`
  cursor: pointer;
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 100%;
  color: white;
  background-color: #10aa7e;
  -webkit-box-shadow: 1px 5px 37px -2px rgba(47, 195, 175, 0.49);
  -moz-box-shadow: 1px 5px 37px -2px rgba(47, 195, 175, 0.49);
  box-shadow: 1px 5px 37px -2px rgba(47, 195, 175, 0.49);

  ${({ className }) =>
    className === 'active' &&
    css`
      background-color: rgb(37 105 86);
    `}

  :hover {
    background-color: #36e0b0;
    -webkit-box-shadow: 0px 0px 41px 17px rgba(74, 191, 155, 0.2);
    -moz-box-shadow: 0px 0px 41px 17px rgba(74, 191, 155, 0.2);
    box-shadow: 0px 0px 41px 17px rgba(74, 191, 155, 0.2);
  }

  @media screen and (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`;
