import { FC } from 'react';
import { StyledSection } from './styled';
import { SectionType } from './types';
import { Marketing } from './components/Marketing';

export const Section: FC<SectionType> = ({ children, marketing }) => {
  const { isMarketing, Blocks } = marketing;

  return (
    <>
      {isMarketing && <Marketing isMarketing={isMarketing ?? false} Blocks={Blocks ?? 'all'} />}
      <StyledSection>{children}</StyledSection>
    </>
  );
};
