import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AboutSection, HelloSection, ProjectSection, RoadmapSection } from './components/Tabs';
import { StyledBodyLanding, StyledLandingPage, StyledContent, BodyWrapper, BackgroundVideo } from './styled';
import { LandingOptionType } from './types';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { RootState } from '../../redux/store';
import { FaqSection } from './components/Tabs/FaqSection';

export const Landing: FC<LandingOptionType> = ({ content }) => {
  const { header, tabs, footer } = content;
  const { helloSectionOption, aboutSectionOption, projectSectionOption, roadmapSectionOption, faqSectionOption } = tabs;

  const { selectedTabLanding } = useSelector(({ AppReducer }: RootState) => AppReducer);

  return (
    <StyledLandingPage className={'landing'}>
      <StyledContent>
        <Header {...header} />
        <BodyWrapper>
          <StyledBodyLanding>
            {selectedTabLanding === '#' && <HelloSection {...helloSectionOption} />}
            {selectedTabLanding === '#about' && <AboutSection {...aboutSectionOption} />}
            {selectedTabLanding === '#project' && <ProjectSection {...projectSectionOption} />}
            {selectedTabLanding === '#roadmap' && <RoadmapSection {...roadmapSectionOption} />}
            {selectedTabLanding === '#faq' && <FaqSection {...faqSectionOption} />}
          </StyledBodyLanding>
        </BodyWrapper>
        <Footer {...footer} />
        <BackgroundVideo src="./background/background.mp4" autoPlay muted loop />
      </StyledContent>
    </StyledLandingPage>
  );
};
