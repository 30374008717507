import styled from 'styled-components';

export const StyledCardIcon = styled.a`
  img {
    position: relative;
  }
  margin-top: 5px;
  height: 12px;
  width: 12px;
`;
