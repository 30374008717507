import {
  HelloSectionOption,
  AboutSectionOption,
  ProjectSectionOption,
  RoadmapSectionOption,
  FaqSectionOption,
} from './content';

export const sections = {
  helloSectionOption: HelloSectionOption,
  aboutSectionOption: AboutSectionOption,
  projectSectionOption: ProjectSectionOption,
  roadmapSectionOption: RoadmapSectionOption,
  faqSectionOption: FaqSectionOption,
};
