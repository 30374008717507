import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LogoPropsType } from '../../../app/Landing/types';
import { StyledLogo } from './styled/Logo.styled';
import { appStoreActions } from '../../../redux/reducer/app-reducer/reducer';
import { RootState } from '../../../redux/store';

export const Logo: FC<LogoPropsType> = ({ logo, altText, borderRight }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLogin } = useSelector(({ AppReducer }: RootState) => AppReducer);

  const basePage = () => {
    navigate('/');
    if (!isLogin) {
      dispatch(appStoreActions.setSelectedTabLanding('#'));
    }
  };

  return (
    <StyledLogo className="logo" borderRight={borderRight} onClick={basePage}>
      <img src={logo} alt={altText} />
    </StyledLogo>
  );
};
