import { FC, Fragment, useId, useState } from 'react';

import { StyledFaq } from './styled';
import { Section } from '../../../../../components';
import { Body3, H2, H3 } from '../../../../../components/Base';
import { FaqSectionType } from '../../../types';
import { AnswerWrapper, FaqWrapper, QuestionWrapper } from './styled/Faq.styled';

export const FaqSection: FC<FaqSectionType> = ({ head, listQuestion }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeElement, setActiveElement] = useState<number>(-1);

  const activeAnswer = (i: number) => {
    setActiveElement(i);
    setIsOpen(!isOpen);
  };

  return (
    <Section
      marketing={{
        isMarketing: false,
      }}
      children={
        <StyledFaq>
          <H2 color="rgb(16, 170, 126)">{head}</H2>
          <FaqWrapper>
            {listQuestion.map((question, index) => (
              <Fragment key={`item_question-${useId()}`}>
                <QuestionWrapper onClick={() => activeAnswer(index)}>
                  <H3 color="#b1b1b1">{question.question}</H3>
                  {isOpen && activeElement === index && (
                    <AnswerWrapper active={isOpen}>
                      <Body3 color="white">{question.answer}</Body3>
                    </AnswerWrapper>
                  )}
                </QuestionWrapper>
              </Fragment>
            ))}
          </FaqWrapper>
        </StyledFaq>
      }
    />
  );
};
