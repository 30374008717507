import styled from 'styled-components';

export const StyledGamesList = styled.div`
  margin: 0 auto;
  width: 70vw;
  height: calc(100vh - 220px);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;

  @media screen and (min-height: 375px) {
    margin-top: 10%;
  }

  @media screen and (max-width: 680px) and (min-height: 390px) {
    flex-wrap: wrap;
    height: fit-content;
  }
`;

export const StyledGamesDashboard = styled.div`
  width: 100vw;
  height: calc(100vh - 420px);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 680px) and (min-height: 390px) {
    height: calc(100vh - height);
  }
`;
