import { FC } from 'react';

import { Section } from '../../../../components';
import { StyledGamesDashboard } from './styled';
import { GamesList } from './components/GamesList/GamesList';

export const GamesDashboard: FC = () => {
  return (
    <Section
      marketing={{
        isMarketing: false,
        Blocks: 'only-up',
      }}
      children={
        <StyledGamesDashboard>
          <GamesList />
        </StyledGamesDashboard>
      }
    />
  );
};
