import { Dispatch, FC, SetStateAction, useEffect, useId } from 'react';
import { gameMock } from '../../../app/Gambling/components/GamesDashboard/game-mock';
import { usePagination } from '../../../hooks';
import { Body3 } from '../Text';
import { CountPage, PageItem, StyledPagination } from './styled';
import { PaginationType } from './types';

export const Pagination: FC<PaginationType> = ({ elementsOnPage, data, setElementOnPage }) => {
  const { firstContentIndex, lastContentIndex, nextPage, prevPage, page, setPage, totalPages } = usePagination({
    contentPerPage: elementsOnPage,
    count: data.length,
  });

  useEffect(() => {
    setElementOnPage({
      firstContentIndex: firstContentIndex,
      lastContentIndex: lastContentIndex,
    });
  }, [firstContentIndex, lastContentIndex]);

  return (
    <>
      <StyledPagination>
        {/* <CountPage>
          <Body3 color="white" className="text">
            {page}/{totalPages}
          </Body3>
        </CountPage> */}
        <PageItem onClick={prevPage} className="page">
          --i
        </PageItem>
        <CountPage>
          <Body3 color="white" className="text">
            {page}/{totalPages}
          </Body3>
        </CountPage>
        {/* {[...Array(totalPages / 2).keys()].map((el) => (
          <PageItem
            onClick={() => setPage(el + 1)}
            key={`page_item-${useId()}`}
            className={`${page === el + 1 ? 'active' : ''}`}
          >
            {el + 1}
          </PageItem>
        ))} */}
        <PageItem onClick={nextPage} className="page">
          i++
        </PageItem>
      </StyledPagination>
    </>
  );
};
