export const roadmap = [
  {
    date: "June '23",
    description: 'Release service',
  },
  {
    date: "August '23",
    description: 'Chat integration for users',
  },
  {
    date: "October '23",
    description: 'Release multiplayer mode for game',
  },
  {
    date: '...',
    description: 'In the process of development',
  },
];
