import styled from 'styled-components';

export const StyledFooter = styled.div`
  height: 110px;
  width: 100vw;
  display: grid;
  grid-template-columns: 130px 1fr 230px;
  align-items: center;

  /* -webkit-box-shadow: -4px -3px 97px -15px rgba(34, 155, 167, 0.2) inset;
  -moz-box-shadow: -4px -3px 97px -15px rgba(34, 155, 167, 0.2) inset;
  box-shadow: -4px -3px 97px -15px rgba(34, 155, 167, 0.2) inset; */

  @media screen and (max-width: 980px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 61px;
    opacity: 0.1;

    .logo {
      display: none;
    }
  }

  @media screen and (max-width: 420px) {
    display: none;
  }

  @media screen and (max-height: 800px) {
    display: none;
  }
`;
