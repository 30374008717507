import './App.css';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from './redux/store';
import { Route, Routes } from 'react-router-dom';
import { Landing } from './app/Landing';
import { Loader } from './components/Base';

import { GamblingContentOption, LandingContentOption } from './content';
import { Suspense, useEffect } from 'react';
import { Gambling } from './app/Gambling/Gambling';
import { checkNetworkBlockchain } from './services/contract/src/contract';

function App() {
  const dispatch = useDispatch();
  const { chainId, isLogin, userAddress } = useSelector(({ AppReducer }: RootState) => AppReducer);

  // useEffect(() => {
  //   // checkNetworkBlockchain(dispatch);
  // }, [chainId, userAddress]);

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Routes>
          {isLogin ? (
            <Route path="*" element={<Gambling content={GamblingContentOption} />} />
          ) : (
            <Route path="*" element={<Landing content={LandingContentOption} />} />
          )}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
