import { FC, useState } from 'react';
import { MobileMenuType } from '../../../app/Landing/types';
import { StyledMenu } from '../../Header/components/MobileMenu/styled';

export const Menu: FC<MobileMenuType> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openOrCloseMobileMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <StyledMenu onClick={() => openOrCloseMobileMenu()}>
      <img
        style={{ zIndex: 1000, padding: '36px' }}
        src={!isOpen ? '/images/icons/menu.svg' : '/images/icons/close.svg'}
        alt="menu"
      />
      <div className={`wrapperMenu${isOpen ? '-visible' : ''}`} style={{ height: '100%', width: '100%', zIndex: 999 }}>
        {isOpen && children}
      </div>
    </StyledMenu>
  );
};
