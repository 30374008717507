import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNavMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 80vw;
  color: white;
  gap: 80px;

  text-transform: uppercase;
  font-family: 'Mazzard';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.035em;
  cursor: pointer;
`;

export const NavMenuItem = styled.div`
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
`;
