import styled from 'styled-components';

export const StyledSocial = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  @media screen and (max-width: 980px) {
    img {
      width: 40px;
    }
  }
`;
