import { FC, useEffect } from 'react';
import { StyledGame } from './styled';
import { Iframe } from '../../../../components/Base/Iframe/Iframe';
import { Section } from '../../../../components';

export const Game: FC<{ src: string }> = ({ src }) => {
  return (
    <Section
      marketing={{
        isMarketing: false,
        Blocks: 'all',
      }}
      children={
        <StyledGame>
          <Iframe className="game-iframe" src={src} />
        </StyledGame>
      }
    />
  );
};
