import { FC, useState } from 'react';
import { ButtonPosition, ImgMarketing, RightBlockContent, StyledRightBlock } from './styled';
import { Button } from '../../../../../Base';

export const RightBlock: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const closeUpBlock = () => {
    setIsVisible(false);
  };

  return (
    <StyledRightBlock isVisible={isVisible}>
      <RightBlockContent>
        <ImgMarketing
          src="https://images.unsplash.com/photo-1473773508845-188df298d2d1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80"
          alt=""
        />
        <ButtonPosition>
          <Button
            children={'Close'}
            handleClick={() => {
              closeUpBlock();
            }}
            type={'secondary-l'}
          />
        </ButtonPosition>
      </RightBlockContent>
    </StyledRightBlock>
  );
};
