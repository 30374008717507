import React, { FC, useId } from 'react';
import { StyledSocial } from './styled/Social.styled';
import { SocFooterPropsType } from '../../../../app/Landing/types';

export const Social: FC<SocFooterPropsType> = ({ socials }) => {
  return (
    <StyledSocial {...{}}>
      {socials.map(({ imgUrl, altText }) => (
        <img key={`soc_icon-${useId()}`} src={imgUrl} alt={altText} />
      ))}
    </StyledSocial>
  );
};
