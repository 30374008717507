import { FC, useId, useState } from 'react';
import { useNavigate } from 'react-router';

import { gameMock } from '../../game-mock';
import { Card } from '../../../../../Landing/components/Tabs/CardsSection/components/Card';
import { Pagination } from '../../../../../../components/Base/Pagination';
import { StyledGamesList } from '../../styled';
import { PaginationOptions } from '../../../types';

export const GamesList: FC = () => {
  const navigate = useNavigate();
  const [gameOnPage, setGameOnPage] = useState<PaginationOptions>({
    firstContentIndex: 0,
    lastContentIndex: 4,
  });
  const { firstContentIndex, lastContentIndex } = gameOnPage;

  return (
    <>
      <StyledGamesList>
        {gameMock &&
          gameMock
            .slice(firstContentIndex, lastContentIndex)
            .map(({ img, title, description, path }) => (
              <Card
                key={`game_card-${useId()}`}
                className="game-card"
                img={img}
                title={title}
                description={description}
                onClick={() => navigate(path)}
              />
            ))}
      </StyledGamesList>
      <Pagination elementsOnPage={4} setElementOnPage={setGameOnPage} data={gameMock} />
    </>
  );
};
