import styled from 'styled-components';

export const StyledGamblingPage = styled.div`
  width: 100%;
`;

export const StyledContent = styled.div`
  height: 100vh;

  display: grid;
  grid-template-rows: 110px 1fr 110px;

  @media screen and (max-height: 420px) {
    grid-template-rows: 60px 1fr;
  }
`;

export const StyledBodyGambling = styled.div`
  display: flex;
  /* margin-top: 110px; */
  height: max-content;
  width: 100%;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 980px) {
    margin: 0 auto;
  }
`;

export const BodyWrapper = styled.div`
  /* display: grid; */
  /* align-items: center; */
  /* grid-template-columns: 130px 1fr; */

  /* @media screen and (max-width: 980px) {
    display: flex;
  } */
`;

export const BackgroundVideo = styled.video`
  position: absolute;
  margin: 0;
  z-index: -1;

  @media screen and (max-width: 980px) {
    position: fixed;
  }
`;
