import styled, { css, keyframes } from 'styled-components';

const animationVisibleBlock = keyframes`
  from {
    opacity: 0;
    margin-right: -500px;
  } to {
    opacity: 0.15;
    margin-right: 0;
  }
`;

const animationUnVisibleBlock = keyframes`
  to {
    opacity: 0;
    margin-right: -500px;
    display: none;
  }
`;

export const StyledRightBlock = styled.span<{ isVisible: boolean }>`
  animation: ${animationVisibleBlock} 0.7s;
  grid-row: 2;
  grid-column: 3;
  margin-left: 250px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0.15;
  transition: all 0.3s ease;
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  transform: scale(1.2);
  z-index: 1000;

  :hover {
    filter: none;
    -webkit-filter: none;
    -moz-filter: none;
    transition: all 0.5s ease;
    opacity: 1;
    margin-left: 0px;
  }

  ${({ isVisible }) =>
    !isVisible &&
    css`
      animation: ${animationUnVisibleBlock} 1s;
      display: none;
    `}
`;

export const RightBlockContent = styled.div`
  width: 80%;
  height: 90%;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  border-radius: 20px;
  transition: all 0.5s ease;

  :hover {
    width: 90%;
    height: 100%;

    -webkit-box-shadow: 0px 0px 38px 30px rgba(16, 170, 126, 0.27);
    -moz-box-shadow: 0px 0px 38px 30px rgba(16, 170, 126, 0.27);
    box-shadow: 0px 0px 38px 30px rgba(16, 170, 126, 0.27);
  }
`;

export const ImgMarketing = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;
`;

export const ButtonPosition = styled.div`
  position: absolute;
`;
