import { HeaderOptionType } from '../../../../app/Landing/types';
import { navMenu, logo, buttonType, buttonName, isMobile } from './content';

export const header: HeaderOptionType = {
  navMenu,
  logo,
  button: {
    buttonName,
    buttonType,
  },
  isMobile,
};
