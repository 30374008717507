import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppState, NotificationType } from '../../types';

const initialState: IAppState = {
  isLoading: false,
  notification: {
    isActive: false,
    mode: undefined,
    message: undefined,
  },
  userAddress: '',
  chainId: 0,
  isLogin: false,
  selectedTabLanding: '#',
  selectedTabGambling: '#',
  language: 'en',
};

export const appStore = createSlice({
  name: 'AppStore',
  initialState,
  reducers: {
    resetApp(state) {
      state = { ...initialState };
    },
    isLoading(state) {
      state.isLoading = !state.isLoading;
    },
    notification(state, { payload }: PayloadAction<NotificationType>) {
      state.notification = payload;
    },
    notificationClose(state) {
      state.notification = {
        isActive: false,
        mode: undefined,
        message: undefined,
      };
    },
    setUserAddress(state, { payload }: PayloadAction<string>) {
      state.userAddress = payload;
    },
    setChainId(state, { payload }: PayloadAction<number>) {
      state.chainId = payload;
    },
    setLogin(state, { payload }: PayloadAction<boolean>) {
      state.isLogin = payload;
    },
    setSelectedTabLanding(state, { payload }: PayloadAction<string>) {
      state.selectedTabLanding = payload;
    },
    setSelectedTabGambling(state, { payload }: PayloadAction<string>) {
      state.selectedTabGambling = payload;
    },
    setLanguage(state, {payload}: PayloadAction<'en' | 'sp' | 'ru'>) {
      state.language = payload;
    }
  },
});

export const appStoreActions = {
  ...appStore.actions,
};

export const AppReducer = appStore.reducer;
