import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StyledHelloInfo, StyledHelloSection, HeadDescriptionWrapper } from './styled';
import { HelloSectionType } from '../../../types';
import { Section } from '../../../../../components';
import { H1, Body2, Button } from '../../../../../components/Base';
import { connectDapp } from '../../../../../services/contract/src/contract';

const defaultButtonName = (t) => (
  <div
    style={{
      margin: 0,
      display: 'flex',
      gap: '10px',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    {t('Connect metamask')}
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3119 7.99106L12.3288 4.00876L13.3787 2.95898L19.1544 8.73347L13.3787 14.508L12.3288 13.4582L16.3119 9.47588H0.273438V7.99106H16.3119Z"
        fill="white"
      />
    </svg>
  </div>
);
export const HelloSection: FC<HelloSectionType> = ({
  helloSectionContent,
  bigLogoForSection,
  isButtonConnect,
  button,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const connectingToDapp = () => connectDapp(dispatch);

  return (
    <Section
      marketing={{
        isMarketing: false,
      }}
      children={
        <StyledHelloSection>
          <StyledHelloInfo>
            <HeadDescriptionWrapper>
              <H1 color="white">{t(helloSectionContent.head)}</H1>
              <Body2 color="white">{t(helloSectionContent.description as string)}</Body2>
            </HeadDescriptionWrapper>
            {isButtonConnect && (
              <Button
                type={button.buttonType}
                children={t(button.buttonName) ?? defaultButtonName(t)}
                handleClick={() => {
                  connectingToDapp();
                }}
              />
            )}
          </StyledHelloInfo>
          {/* <video src={bigLogoForSection.imgUrl} autoPlay muted loop /> */}
        </StyledHelloSection>
      }
    />
  );
};
