import styled, { css } from 'styled-components';

export const StyledSection = styled.div<{ scroll?: boolean }>`
  width: 100%;
  ${({ scroll }) =>
    scroll &&
    css`
      overflow-x: scroll;
    `}

  @media screen and (max-width: 420px) {
    position: relative;
  }
`;
