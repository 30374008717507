import { FC } from 'react';
import { MarketingBlock } from './styled';
import { LeftBlock, RightBlock, UpBlock } from './components';
import { MarketingType } from '../../types';

export const Marketing: FC<MarketingType> = ({ isMarketing, Blocks }) => {
  return isMarketing ? (
    (Blocks === 'all' && (
      <MarketingBlock isMarketing={isMarketing}>
        <UpBlock />
        <RightBlock />
        <LeftBlock />
      </MarketingBlock>
    )) ||
      (Blocks === 'only-up' && (
        <MarketingBlock isMarketing={isMarketing}>
          <UpBlock />
        </MarketingBlock>
      )) ||
      (Blocks === 'only-middle' && (
        <MarketingBlock isMarketing={isMarketing}>
          <RightBlock />
          <LeftBlock />
        </MarketingBlock>
      )) || <></>
  ) : (
    <></>
  );
};
