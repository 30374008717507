import { FC, useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RightMobileWrapper, StyledMobileMenu } from './styled';
import { Body2, Button, Menu } from '../../../Base';
import { SelectLanguageContainer } from '../../../Base/SelectLanguage';
import { appStoreActions } from '../../../../redux/reducer/app-reducer/reducer';
import { NavMenuPropsType, NavMenuType } from '../../../../app/Landing/types';
import { connectDapp } from '../../../../services/contract/src/contract';
import { RootState } from '../../../../redux/store';
import { LoginAddress } from '../../styled';
import { ProfileList } from '../../Header';
import { useNavigate } from 'react-router-dom';

export const MobileMenu: FC<NavMenuPropsType> = ({ menu }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLogin, userAddress } = useSelector(({ AppReducer }: RootState) => AppReducer);
  const [isOpenProfileList, setIsOpenProfileList] = useState<boolean>(false);

  const connectingToDapp = () => connectDapp(dispatch);

  const openProfileList = () => {
    setIsOpenProfileList(!isOpenProfileList);
  };

  const setSelectedTabLanding = (tabName: string) => {
    dispatch(appStoreActions.setSelectedTabLanding(`#${tabName}`));
  };

  const exitDapp = () => {
    window.localStorage.setItem(
      'EnkiGameAppState',
      JSON.stringify({
        chainId: 0,
        userAddress: '',
        isLogin: false,
      }),
    );

    dispatch(appStoreActions.resetApp());
    window.location.reload();
    setIsOpenProfileList(false);
  };

  const handleClickForPathMenu = (element: NavMenuType) =>
    isLogin && element.title === 'Home' ? navigate('/') : setSelectedTabLanding(element.path);

  return (
    <>
      <Menu
        setIsOpenProfileList={() => isOpenProfileList && setIsOpenProfileList(!isOpenProfileList)}
        children={
          <StyledMobileMenu>
            {isLogin ? (
              <Button
                type={'primary-b'}
                children={t('Exit') as string}
                handleClick={() => {
                  exitDapp();
                }}
              />
            ) : (
              <Button
                type={'primary-b'}
                children={t('Connect Metamask') as string}
                handleClick={() => {
                  connectingToDapp();
                }}
              />
            )}
            {menu.map((element) => (
              <Button
                key={`mobile_menu-${useId()}`}
                children={element.title}
                type={'primary-l'}
                handleClick={() => {
                  handleClickForPathMenu(element);
                }}
              />
            ))}
          </StyledMobileMenu>
        }
      />
      <RightMobileWrapper>
        <SelectLanguageContainer />
        {isLogin && (
          <LoginAddress onClick={openProfileList}>
            <Body2 color="#10aa7e">{`${userAddress.slice(0, 5)}...${userAddress.slice(-4)}`}</Body2>
            {isOpenProfileList && <ProfileList setIsOpenProfileList={setIsOpenProfileList} />}
          </LoginAddress>
        )}
      </RightMobileWrapper>
    </>
  );
};
