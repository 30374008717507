import { QuestionType } from '../../../../../../../app/Landing/types';

export const listQuestion: QuestionType[] = [
  {
    question: 'Any question 1',
    answer: 'Any answer answer answer answer answer answer answer answer answer',
  },
  {
    question: 'Any question 2',
    answer: 'Any answer answer answer answer answer answer answer answer answer',
  },
  {
    question: 'Any question 3',
    answer: 'Any answer answer answer answer answer answer answer answer answer',
  },
  {
    question: 'Any question 4',
    answer: 'Any answer answer answer answer answer answer answer answer answer',
  },
];
