import { FC, useId } from 'react';
import { Section } from '../../../../../components';
import { StyledRoadmap, RoadItem, RoadDescription, RoadmapWrapper, RoadItemWrapper } from './styled';
import { RoadmapSectionType } from '../../../types';
import { H2, Body2 } from '../../../../../components/Base';

export const RoadmapSection: FC<RoadmapSectionType> = ({ head, roadmap }) => {
  return (
    <Section
      marketing={{
        isMarketing: false,
      }}
      children={
        <StyledRoadmap>
          <H2 color="rgb(16, 170, 126)">{head}</H2>
          <RoadmapWrapper>
            {roadmap.map((item) => (
              <RoadItemWrapper key={`roadmap_item-${useId()}`}>
                <RoadDescription className="description-roadmap">{item.description}</RoadDescription>
                <RoadItem>{item.date}</RoadItem>
              </RoadItemWrapper>
            ))}
          </RoadmapWrapper>
        </StyledRoadmap>
      }
    />
  );
};
