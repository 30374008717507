import styled, { css } from 'styled-components';

export const MarketingBlock = styled.div<{ isMarketing?: boolean }>`
  display: none;

  ${({ isMarketing }) =>
    isMarketing &&
    css`
      display: grid;
      grid-template-rows: 160px calc(100vh - (160px + 228px));
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      position: absolute;

      @media screen and (max-width: 768px) {
        display: none;
      }
    `}
`;
